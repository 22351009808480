import axios from 'axios'

export default {
  login({commit}, user){
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios({url: 'https://tv.xleb.ru/api/v1/auth/login?email='+user.username+'&password='+user.password, data: user, method: 'POST' })
      .then(resp => {
        const token = 'Bearer '+resp.data.access_token
        const username = user.username
        localStorage.setItem('token', token)
        axios.defaults.headers.common['Authorization'] = token
        axios.interceptors.request.use(function (config) {
          config.headers.Authorization =  token;
          return config;
        });
        commit('auth_success', token, username)
        resolve(resp)
      })
      .catch(err => {
        commit('auth_error')
        localStorage.removeItem('token')
        reject(err)
      })
    })
  },
  logout({commit}){
    return new Promise((resolve) => {
      commit('logout')
      resolve()
    })
  },


  /* Получение списка касс для отправки сообщений */
  getAllCashiers({commit}){
    axios({url: 'https://tv.xleb.ru/api/v1/secure/cashier/all', method: 'GET' })
    .then(response=>{
      console.log('getAllCashiers starts, ', response.data)
      if(response.data.length) commit('SET_ALL_CASHIERS', response.data)
    })
  },
  /*  */
}