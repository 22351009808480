<template>
  <transition name="el-zoom-in-top">
    <div class="send-messages">
      <el-card class="box-card mb-4">
        <el-collapse v-model="activeSections">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div><strong>Отправка сообщений на кассы ТТ</strong></div>
            </template>
            <el-alert
              title="Информация"
              type="info"
              :closable="false"
              show-icon
              class="mb-3"
              description="На любую из касс можно отправить сообщение. Выберите кассу, наберите текст сообщение и нажмите на кнопку отправить!
               Сообщение будет видно на кассе около 10 минут.">
            </el-alert>
            <el-select v-model="selectValue" class="my-3 w-100">
              <el-option
                v-for="(shop, index) in allCashiers"
                :key="index"
                :data-id="index"
                :label="shop.description"
                :value="shop.id">
                <span style="float: left">{{ shop.description }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">id: {{ shop.id }}</span>
              </el-option>
            </el-select>     
            <br> 
              <el-input
                type="textarea"
                :rows="2"
                placeholder="Текст сообщения..."
                v-model="textarea">
              </el-input> 
            <br>   
            <br>   
            <el-button type="success" @click="sendMessage" round>Отправить</el-button>  
          </el-collapse-item>
        </el-collapse>
      </el-card>  
    </div>
  </transition>
</template>


<script>
import {mapState} from 'vuex'
export default {
  name: 'DetailShops',
  components:{},
  inject: {
    swal: {
      from: 'swal'
    },
    axios: {
      from: 'axios'
    }
  },
  data: function () {
    return {
      activeSections: ['1','2'],
      selectValue: '',
      textarea: ''
    }
  },
  beforeMount: function(){
    this.$store.dispatch('getAllCashiers')
  },
  mounted: function () {
  },
  methods: {
    sendMessage(){
      var config = {
        method: 'post',
        url: 'https://tv.xleb.ru/api/v1/secure/cashier/send-message',
        data: { 
          'message': this.textarea,
          'cashier': this.selectValue
        }
      };

      this.axios(config)
      .then(response=>{
        console.log(JSON.stringify(response.data));
        this.$message({
          showClose: true,
          message: 'Сообщение успешно отправлено!',
          type: 'success',
          customClass: 'message'
        });
      })
      .catch(error=>{
        console.log(JSON.stringify(error));
        this.$message({
          showClose: true,
          message: 'Не удалось отправить сообщение!',
          type: 'error',
          customClass: 'message'
        });
      });
    }
  },
  computed: {
    ...mapState({
      allCashiers: state => state.allCashiers,
    }),
  }
}
</script>



<style lang="scss">
  .send-messages{
    padding: 1px 0;
    & .el-card, & .el-collapse-item__wrap{
      overflow: visible;
    }

    &  .el-divider .el-divider__text{
      background: #eee;
    }

    & .el-date-editor{
      max-width: 100%;
    }
  }
  .el-select{
    max-width: 100%;
    width: 400px;
  }
  .el-select-dropdown__item.selected{
    color: #ff6900;
  }
  .el-card__body{
    padding: 0 20px;
  }
  .el-collapse{
    border: none;
  }
  .el-collapse-item__header{
    border: none;
    line-height: unset !important;
    & > div{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .message{
    z-index: 99999 !important;
  }
  .echarts {
    height: 400px;
  }
  .datepicker-adaptive{
    @media (max-width: 700px) {
      & {
        max-width: 100%;
      }
      & .el-date-range-picker__content{
        width: 100%;
      }
      & .el-picker-panel {
        max-width: 100%;
      }
      & .el-picker-panel__sidebar{
        display: none;
      }
      & .el-picker-panel__body{
        display: flex;
        flex-direction: column;
        min-width: unset;
        max-width: 100%;
        font-size: 12px;
        margin-left: 0;
      }
      & .el-date-range-picker__content.is-left {
        border-right: none;
      }
    }

  }
</style>