<template>
  <aside class="main-sidebar">
    <div>
      <section class="sidebar">
        <ul class="sidebar-menu">
          <li class="header">Управление</li>
          <li class="treeview">
            <router-link to="/">
              <i class="el-icon-monitor"></i> 
              <span>Управление мониторами</span> 
            </router-link>
          </li>
          <li class="treeview">
            <router-link to="/messages">
              <i class="el-icon-message"></i> 
              <span>Отправка сообщений</span> 
            </router-link>
          </li>  
        </ul>
      </section>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'DashboardSidebar',
  mounted: function () {

  }
}
</script>