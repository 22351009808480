<template>
	<div class="login">
		<div class="login__center">
			<div class="wrap-login">
				<form class="login__form validate-form">
				<div class="login__logo-2">
					<img src="./../../static/img/logo.svg" alt="logo">
				</div>
				<span class="login__form-title pt-5">
					Хлебник
				</span>

				<div class="input-group mt-5" :class="focusedName">
					<input type="text" name="login" placeholder="Логин" v-model="username.value" @focus="username.focused = true" @blur="username.focused = false"/>
					<i class="el-icon-user"></i>
				</div>

				<div class="input-group my-5" :class="focusedPswd">
					<input type="password" name="password" placeholder="Пароль" v-model="password.value" @focus="password.focused = true" @blur="password.focused = false"/>
					<i class="el-icon-lock"></i>
				</div>
				<div class="login__buttons mb-4 text-center">
					<el-button round @click="login">Войти</el-button>
				</div>
			</form>
		</div>
	</div>
</div>
  
</template>
<script>
  export default {
		inject: {
			swal: {
				from: 'swal'
			}
		},
    data(){
      return {
        username: {
					value: "",
					focused: false,
				},
        password: {
					value: "",
					focused: false,
				},
      }
    },
    methods: {
      login: function () {
        let username = this.username.value 
        let password = this.password.value
        this.$store.dispatch('login', { username, password })
        .then(()=>{
					this.$router.push({ name: 'Raspberry' })
				})
        .catch(err => {
					console.log(err)
					this.swal.fire(
						'Ошибка!',
						'Доступ запрещен! Причина: '+ err.error,
						'error'
          )
				})
      },
			focused:(el)=>{
				console.log(el)
			}
    },
		computed: {
			focusedName(){
				return{
					focused: this.username.value.length || this.username.focused
				}
			},
			focusedPswd(){
				return{
					focused: this.password.value.length || this.password.focused
				}
			}
		}
  }
</script>
<style lang="scss">
.wrap-login{
	width: 500px;
	border-radius: 10px;
	overflow: hidden;
	padding: 55px 55px 37px;
	background: #ff6000;
	background: -webkit-linear-gradient(top,#7579ff,#6f00ff);
	background: -o-linear-gradient(top,#7579ff,#6f00ff);
	background: -moz-linear-gradient(top,#7579ff,#6f00ff);
	background: linear-gradient(top,#7579ff,#6f00ff);
	max-width: 100%;
	margin: 0 auto;
	@media (max-width: 700px) {
		padding: 25px 25px 18px;
	}
}

.input-group{
	position: relative;
	&::before{
		display: block;
		content: '';
		border-bottom: 2px solid white;
		width: 0%;
		position: absolute;
		bottom: 2px;
		left: 0;
		transition: all .3s ease-in-out;
	}
	&.focused{
		input{
			padding-left: 0;
		}
		input + i{
			left: 0px;
			bottom: 30px;
			font-size: 18px;
		}
		&::before{
			width: 100%;
		}
	}
}

.login{
	position: fixed;
	overflow: auto;
	left: 0;
	top: 0;
	width: 100vw;
	padding: 0 15px;
	height: 100vh;
	background-color: #fff;
	background-image: url('./../../static/img/bg-login.jpeg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 15px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	&:before{
		content: "";
    display: block;
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,.7);
	}
	&__center{
		min-height: 100%;
		display: flex;
		align-items: center;
	}
	z-index: 9999;
	justify-content: center;
	&__logo{
		display: block;
		height: 100px;
		margin: 0 auto;
		&-2{
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
			border-radius: 50%;
			background-color: #fff;
			padding: 20px;
			height: 150px;
			width: 150px;
			@media (max-width: 700px) {
				height: 75px;
				width: 75px;
			}
			& img{
				max-width: 75%;
			}
		}
	}
	&__form{
		width: 100%;
		max-width: 100%;
		&-title{
			font-size: 30px;
			color: #fff;
			line-height: 1.2;
			text-align: center;
			text-transform: uppercase;
			display: block;
			font-weight: bold;
		}
		& input{
			outline:none !important;
			position: relative;
			font-size: 16px;
			color: #fff;
			line-height: 1.2;
			display: block;
			width: 100%;
			height: 45px;
			background: transparent;
			border: none;
			padding: 0 5px 0 5px;
			border-bottom: 2px solid rgba(255,255,255, .2);
			padding-left: 38px;
			&::placeholder{
				color: white;
			}
			&:focus{
				padding-left: 0;
				&::placeholder{
					color: transparent;
				}
			}
		}
		& input + i{
			pointer-events: none;
			color: white;
			position: absolute;
			left: 0px;
			bottom: 0px;
			font-size: 24px;
			height: 100%;
			width: 100%;
			display: block;
			transition: all .3s ease-in-out;
			&:before{
				position: absolute;
				left: 5px;
				bottom: 15px;
			}
		}
	}
	&__buttons{
		// display: flex;
		& .el-button{
			height: 50px;
			padding: 0 20px;
			border-radius: 25px;
			width: 150px;
			font-size: 16px;
		}
		& .el-button:active, & .el-button:hover, & .el-button:focus{
			border: 1px solid #DCDFE6;
			outline: none;
			color: #606266;
		}
		& span{
			font-weight: bold;
		}
	}
}
</style>