import axios from 'axios'

export default {
  /* Получение списка магазина по малинкам */
  getRsbShopList({commit}){
    axios({url: 'https://tv.xleb.ru/api/v1/secure/shopLists', method: 'GET' })
    .then(response=>{
      if(response.data.success)commit('SET_RSBPI_SHOP_LIST', response.data.data)
    })
  },
  /*  */

  /* Получение всех малинок */
  getAllRsbs({commit}){
    axios({url: 'https://tv.xleb.ru/api/v1/secure/raspberryPiLists', method: 'GET' })
    .then(response=>{
      if(response.data.success)commit('SET_RSBPI', response.data.data)
    })
  },
  /*  */

  /* Получение всех связей малинос с плейлистами */
  getAllBoundsPlayList({commit}){
    axios({url: 'https://tv.xleb.ru/api/v1/secure/raspberryPlayLists', method: 'GET' })
    .then(response=>{
      if(response.data.success)commit('SET_RSBPI_BOUNDS', response.data.data)
    })
  },
  /*  */

  /* Получение всех плейлистов (плейлисты содержат альбомы с музыкой и картинками) */
  getAllPlayLists({commit}){
    axios({url: 'https://tv.xleb.ru/api/v1/secure/playLists', method: 'GET' })
    .then(response=>{
      if(response.data.success)commit('SET_PLAY_LISTS', response.data.data)
    })
  },
  /*  */

  /* Получение всех альбомов картинок */
  getAllGalleries({commit}){
    axios({url: 'https://tv.xleb.ru/api/v1/secure/galleries', method: 'GET' })
    .then(response=>{
      if(response.data.success)commit('SET_ALL_GALLERIES', response.data.data)
    })
  },
  /*  */

  /* Получение всех альбомов музыки */
  getAllMusicLists({commit}){
    axios({url: 'https://tv.xleb.ru/api/v1/secure/musicPlayLists', method: 'GET' })
    .then(response=>{
      if(response.data.success)commit('SET_ALL_MUSIC_LISTS', response.data.data)
    })
  },
  /*  */
}