import '@babel/polyfill'
import 'mutationobserver-shim'

import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';

import store from './store/index'
import router from './router'

import locale from 'element-plus/lib/locale/lang/ru'
import 'dayjs/locale/ru'

// will auto set Day.js locale to 'zh-cn'

import swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { gsap } from "gsap";
import Axios from 'axios'

const app = createApp(App).use(router).use(store)

Axios.defaults.headers.common['Authorization'] = store.state.token
app.provide('axios', Axios)
app.provide('swal', swal) // - инжектим -> inject: {swal: {from: 'swal'}} --> this.swal
// app.config.globalProperties.$swal = swal; - глобальный импорт через глобальные свойства -> this.$swal
app.config.globalProperties.$gsap = gsap; //Получается подключить только так
app.use(ElementPlus, {locale})
// app.use(gsap)
app.mount('#app')


import 'bootstrap'
import "bootstrap/dist/css/bootstrap.min.css"
import "google-material-color/dist/palette.css"

window.$ = window.jQuery = require('jquery');

require('../node_modules/admin-lte/dist/js/app.min.js')
require('../node_modules/admin-lte/dist/js/demo.js')
require('../node_modules/admin-lte/plugins/slimScroll/jquery.slimscroll.js')
// require('../node_modules/admin-lte/dist/js/app.js')



Axios.interceptors.response.use(undefined, (error) => {
  if (error.response && error.response.status === 401) {
    console.log('Ключ устарел! Необходим повторный вход!')
    router.push('/login')
    store.dispatch('logout')
  }else{
    swal.fire('Ошибка!','Произошла непредвиденная ошибка! Обратитесь к администратору.')
  }
  return Promise.reject(error.response.data);
});


Date.prototype.yyyymmdd = function() {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [this.getFullYear(),
    (mm>9 ? '' : '0') + mm,
    (dd>9 ? '' : '0') + dd
    ].join('-');
};