<template>
  <header class="main-header">
    <router-link to="/" class="logo">
      <span class="logo-mini"><img src="./../../../static/img/logo.svg" alt="Logo"></span> 
      <span class="logo-lg"><img src="./../../../static/img/logo.svg" alt="Logo"><b>Хлеб</b>ник</span> 
    </router-link>
    <nav class="navbar navbar-static-top">
      <a href="#" class="sidebar-toggle" data-toggle="offcanvas" role="button"><span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </a>
      <div class="px-4">
        <el-button type="danger" round @click="logout">Выход</el-button>
      </div>
    </nav>
  </header>
</template>
<script>
  export default {
    name: 'DashboardHeader',
    mounted: function () {

    },
    computed: {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
    },
    methods: {
      logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      }
    },
  }
</script>