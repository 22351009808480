<template>
  <transition name="el-zoom-in-top">
    <div class="raspberry">
      <el-card class="box-card mb-4">
        <el-collapse v-model="activeSections">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div><strong>Просмотр содержимого на мониторах</strong></div>
            </template>
            <el-alert
              title="Информация"
              :closable="false"
              type="info"
              description="В представленном разделе можно посмотреть/удалить текущие картинки и список музыки привязанные к конкретному монитору.
              Если есть дублирующиеся треки они будут выделены красным цветом."
              show-icon>
            </el-alert>
            <el-select v-model="selectedShop" class="my-3">
              <el-option
                v-for="item in listOfShops"
                :key="item.id"
                :data-id="item.id"
                :label="item.address"
                :value="item.id">
                <span style="float: left">{{ item.address }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.active ? 'вкл' : 'выкл'}}</span>
              </el-option>
            </el-select><br>
            <el-select v-model="selectRaspberry" @change="showRaspberryData" :disabled="isShopSelected">
              <el-option
                v-for="item in rsbInCurrentShopFiltered"
                :key="item.id"
                :data-id="item.id"
                :label="item.description"
                :value="item.id">
                <span style="float: left">{{ item.description }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">id: {{ item.id }}</span>
              </el-option>
            </el-select>
            <!-- <br>
            плейлист - {{playlistBoundsCurrentShopFiltered}}
            <br>
            гал и музыка - {{playlistCurrentShopFiltered}}
            <br>
            гал - {{galleryCurrentShopFiltered}} - {{getCurGalleryName}}
            <br>
            плейлист - {{musicListCurrentShopFiltered}} - {{getCurMusicListName}}
            <br>
            {{allGalleries}} -->
            <el-divider v-if="isEmptyImage" content-position="left">Картинки<span v-if="getCurGalleryName"> - {{getCurGalleryName}}</span></el-divider>
            <!-- <draggable v-if="raspData.images" class="el-row dragArea w-full" style="margin-left: -6px; margin-right: -6px;" :list="raspData.images"> -->
              <el-row>
                <el-col :key="index" :xs="12" :sm="12" :md="8" :lg="8" :xl="8" v-for="(img, index) in raspData.images" class="image-wrapper d-flex flex-column justify-content-stretch position-relative is-guttered px-2">
                  <el-image
                    v-if="img.file.split('/').pop().split('.').pop() != 'mp4'"
                    fit="cover"
                    :src="imageUrl(img.file)"
                    :id="img.id"
                    class="rounded">
                    <!-- :preview-src-list="imageUrlList(raspData.images)" -->
                  </el-image>
                  <video v-else :src="imageUrl(img.file)" controls/>
                  <el-popconfirm
                    style="width: 50px;"
                    confirmButtonText='Да!'
                    cancelButtonText='Отменить'
                    @confirm="deleteImage(img.id)"
                    icon="el-icon-info"
                    iconColor="red"
                    :title='`Вы собираетесь удалить картинку из "${getCurGalleryName}". Вы уверены?`'
                  >
                    <template #reference>
                      <div class="d-flex top-0 flex-row justify-content-center delete-image-btn p-3">
                        <i class="el-icon-delete"></i>
                      </div>
                    </template>
                  </el-popconfirm>

                  <div class="text-center">
                    {{img.file.split('/').pop()}}
                  </div>
                </el-col>
              </el-row>
            <!-- </draggable> -->
            <el-divider v-if="isEmptyMusic" content-position="left">Музыка<span v-if="getCurMusicListName"> - {{getCurMusicListName}}</span></el-divider>
            <!-- <draggable v-if="raspData.music" class="dragArea list-group w-full" :list="raspData.music"
              v-bind="dragOptions"
              @start="isDragging = true"
              @end="isDragging = false"> -->
              <el-row>
                <div class="music__wrapper">
                  <div
                    class="raspberry__music-element py-1 m-1 rounded position-relative" 
                    v-for="(music, index)  in raspData.music"
                    :key="index"
                    :id="music.id"
                  >
                    <div class="duration" :style='`width: ${musicCurTimePercent}%`'></div>
                    <div :id='`music-${music.id}`' @click="playOrPause(`music-${music.id}`)" class="d-flex position-absolute top-0 px-1 flex-row align-items-center justify-content-center music-player music-player--stop">
                      <i class="el-icon-video-play"></i>
                      <i class="el-icon-video-pause"></i>
                      <audio :src='`https://tv.xleb.ru/storage/tv/media/sounds/main/${music.file.split("/").pop()}`' />
                      <!-- https://tv.xleb.ru/storage/tv/media/sounds/main/2MARS%20-%20Music%20Box.mp3 -->
                    </div>
                    <el-popconfirm
                      style="width: 30px;"
                      confirmButtonText='Да!'
                      cancelButtonText='Отменить'
                      @confirm="deleteMusic(music.id)"
                      icon="el-icon-info"
                      iconColor="red"
                      :title='`Вы собираетесь удалить саунтрек из "${getCurMusicListName}". Вы уверены?`'
                      >
                      <template #reference>
                        <div class="d-flex position-absolute top-0 px-3 flex-row align-items-center justify-content-center delete-music-btn">
                          <i class="el-icon-delete"></i>
                        </div>
                      </template>
                    </el-popconfirm>
                    {{++index+'. '+music.file.split('/').pop()}}
                  </div>
                </div>
              </el-row>
            <!-- </draggable> -->
          </el-collapse-item>
        </el-collapse>
      </el-card>
      <el-card class="box-card mb-4" v-loading="uploadNewBindings">
        <el-collapse v-model="activeSections">
          <el-collapse-item name="6">
            <template v-slot:title>
              <div><strong>Создание и изменение плейлистов</strong></div>
            </template>
            <el-select v-model="selectedShop_BIND" class="mt-3">
              <el-option
                v-for="item in listOfShops"
                :key="item.id"
                :data-id="item.id"
                :label="item.address"
                :value="item.id">
                <span style="float: left">{{ item.address }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.active ? 'вкл' : 'выкл'}}</span>
              </el-option>
            </el-select><br>
            <el-select v-model="selectRaspberry_BIND" class="my-3" @change="showBindedPlaylist" :disabled="isShopSelected_BIND">
              <el-option
                v-for="item in rsbInCurrentShopFiltered_BIND"
                :key="item.id"
                :data-id="item.id"
                :label="item.description"
                :value="item.id">
                <span style="float: left">{{ item.description }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">id: {{ item.id }}</span>
              </el-option>
            </el-select><br>
            <span>Для изменения привязки выберите плейлист или создайте новый.</span><br>
            <el-select @change="setNewBindig" :disabled="selectRaspberry_BIND==='Выберите монитор...'" v-model="selectedRaspberryPlayList_BIND" class="my-3">
              <el-option
                v-for="item in allPlayLists"
                :key="item.id"
                :data-id="item.id"
                :label="item.description"
                :value="item.id">
                <span style="float: left">{{ item.description }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">id: {{item.id}}</span>
              </el-option>
            </el-select>
            <div v-if="!compareOldAndNewBindings && selectRaspberry_BIND!=='Выберите монитор...'">
              <el-button
                type="success"
                @click="sendNewBinding"
                class="mt-3"
                >Сохранить изменения
              </el-button>
            </div><br>
            <el-input
              placeholder="Введите название нового плейлиста..."
              v-model="newPlayList">
            </el-input>
            <el-button
              type="success"
              @click="createPlayList"
              class="mt-3"
              >Создать
            </el-button>
          </el-collapse-item>
        </el-collapse>
      </el-card>
      <el-card class="box-card mb-4" v-loading="uploadNewBindings">
        <el-collapse v-model="activeSections">
          <el-collapse-item name="4">
            <template v-slot:title>
              <div><strong>Изменение привязки галерей и альбомов к плейлистам</strong></div>
            </template>
            <el-select @change="showGalleryAndAlboms" v-model="selectedRaspberryPlayListForChangeAlboms_BIND" class="my-3">
              <el-option
                v-for="item in allPlayLists"
                :key="item.id"
                :data-id="item.id"
                :label="item.description"
                :value="item.id">
                <span style="float: left">{{ item.description }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">id: {{item.id}}</span>
              </el-option>
            </el-select><br>
            <span>Для изменения привязки выберите галерею, альбом и нажмите сохранить.</span><br>
            <el-select @change="setNewBindig"  v-model="selectedGallery_BIND" class="my-3">
              <el-option
                v-for="item in allGalleries"
                :key="item.id"
                :data-id="item.id"
                :label="item.description"
                :value="item.id">
                <span style="float: left">{{ item.description }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px"></span>
              </el-option>
            </el-select><br>
            <el-select @change="setNewBindig" v-model="selectedAlbom_BIND">
              <el-option
                v-for="item in allMusicLists"
                :key="item.id"
                :data-id="item.id"
                :label="item.description"
                :value="item.id">
                <span style="float: left">{{ item.description }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px"></span>
              </el-option>
            </el-select>
            <div v-if="!compareOldAndNewAlbomsBindings">
              <el-button
                type="success"
                @click="changePlayList"
                class="mt-3"
                >Сохранить изменения
              </el-button>
            </div><br>
          </el-collapse-item>
        </el-collapse>
      </el-card>
      <el-card class="box-card mb-4" v-loading="uploadingImage">
        <el-collapse v-model="activeSections">
          <el-collapse-item name="2">
            <template v-slot:title>
              <div><strong>Добавление картинок и видео</strong></div>
            </template>
            <el-alert
              title="Важно!"
              :closable="false"
              type="warning"
              description="Картинки привязаны к определенными галереям, а галерея в свою очередь привязана к опреледеному монитору. 
              Чтобы правильно закачать картинку убедитесь, что название файла не содержит лишних символов и выбрана верная галерея!"
              show-icon>
            </el-alert>
            <div class="my-3">
              <el-input
                placeholder="Введите название новой галлереи..."
                v-model="newImageGallery">
              </el-input>
              <el-button
                type="success"
                @click="createGallery"
                class="mt-3"
                >Создать
              </el-button>
            </div>
            <el-select v-model="selectedGallery" class="my-3">
              <el-option
                v-for="item in allGalleries"
                :key="item.id"
                :data-id="item.id"
                :label="item.description"
                :value="item.id">
                <span style="float: left">{{ item.description }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px"></span>
              </el-option>
            </el-select>
            <br>
            <el-upload action="https://tv.xleb.ru/api/v1/secure/images" 
              list-type="picture-card" 
              :auto-upload="false"
              :on-remove="handleImageRemove"
              :on-change="handleImageUpload"
              :http-request="uploadImagesToServer"
              ref="upload"
              :multiple="true"
              :file-list="imagesList"
              :limit="5"
              :on-exceed="handleExceed"
              >
              <template #default>
                <i class="el-icon-plus"></i>
              </template>
              <template #file="{file}">
                <div :style="'height: 100%; background-position: center center; background-size: cover; background-image: url('+ file.url + ')'">
                  <!-- <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" /> -->
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      class="el-upload-list__item-delete"
                      @click="handleImageRemove(file, imagesList)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </template>
              <template #tip>
                <div class="el-upload__tip">Не больше 5 файлов за 1 раз</div>
              </template>
            </el-upload>
            <el-button
              style="margin-top: 10px;"
              
              type="success"
              @click="submitImageUpload"
              >Загрузить
            </el-button>
            <el-dialog v-model="dialogVisible">
              <img width="auto" style="min-width:100%;" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-collapse-item>
        </el-collapse>
      </el-card>
      <el-card class="box-card mb-4" v-loading="uploadingMusic">
        <el-collapse v-model="activeSections">
          <el-collapse-item name="3">
            <template v-slot:title>
              <div><strong>Добавление музыки</strong></div>
            </template>
            <el-alert
              title="Важно!"
              :closable="false"
              type="warning"
              description="Музыка привязана к определенными альбомам, а альбомы в свою очередь привязаны к опреледеному монитору. 
              Чтобы правильно закачать музыку убедитесь, что название файла не содержит лишних символов и выбран правильный альбом!"
              show-icon>
            </el-alert>
            <div class="my-3">
              <el-input
                placeholder="Введите название нового альбома..."
                v-model="newMusicGallery">
              </el-input>
              <el-button
                type="success"
                @click="createAlbom"
                class="mt-3"
                >Создать
              </el-button>
            </div>
            <el-select v-model="selectedAlbom" class="my-3">
              <el-option
                v-for="item in allMusicLists"
                :key="item.id"
                :data-id="item.id"
                :label="item.description"
                :value="item.id">
                <span style="float: left">{{ item.description }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px"></span>
              </el-option>
            </el-select>
            <br>
            <el-upload
              class="upload-demo"
              action="https://tv.xleb.ru/api/v1/secure/upload_media"
              :file-list="musicsList"
              :auto-upload="false"
              :on-remove="handleImageRemove"
              :on-change="handleMusicUpload"
              :on-success="function(){console.log('FWEOIKWEFIONEWIFJOIWJ')}"
              :http-request="uploadMusicsToServer"
              :multiple="true"
              :limit="5"
              :on-exceed="handleExceed"
              ref="music"
            >
              <el-button size="small" type="primary">Добавить музыку</el-button>
                <template #tip>
                <div class="el-upload__tip">Не больше 5 файлов за 1 раз</div>
              </template>
            </el-upload>
            <el-button
              style="margin-top: 10px;"
              type="success"
              @click="submitMusicUpload"
              >Загрузить
            </el-button>
          </el-collapse-item>
        </el-collapse>
      </el-card>
    </div>
  </transition>
</template>


<script>
// import { VueDraggableNext } from 'vue-draggable-next'
import {mapState} from 'vuex'

export default {
  name: 'Raspberry',
  components: {
    // draggable: VueDraggableNext,
  },
  inject: {
    swal: {
      from: 'swal'
    },
    axios:{
      from: 'axios'
    }
  },
  data: function () {
    return {
      enabled: true,
      dragging: false,
      activeSections: ['1'],

      selectedShop: 'Выберите торговую точку...',
      selectRaspberry: 'Выберите монитор...',

      selectedGallery: "Выберите галерею...",
      selectedAlbom: "Выберите альбом...",

      raps: [{
        name: '1й монитор',
        id: 2
      },{
        name: '2й монитор',
        id: 1
      },{
        name: '3й монитор',
        id: 3
      }],
      raspData: {},

      selectedShop_BIND: 'Выберите торговую точку...',
      selectRaspberry_BIND: 'Выберите монитор...',
      selectedGallery_BIND: "Не привязана галерея...",
      selectedAlbom_BIND: "Не привязан альбом...",
      selectedPlayList_BIND: "Не привязан плейлист...",
      selectedRaspberryPlayList_BIND: "Не привязан плейлист...",
      selectedRaspberryPlayListForChangeAlboms_BIND: "Не выбран плейлист...",

      oldBinding: [],
      newBinding: [],
      newAlbomsBindings: [],
      oldAlbomsBinding: [],
      raspAlreadyHavePlaylist: false,

      uploadingMusic: false,
      uploadingImage: false,
      uploadNewBindings: false, 

      newPlayList: '',
      newImageGallery: '',
      newMusicGallery: '',

      musicsList: [],
      imagesList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,

      currentAudio: {},
      currentTime: 0,
    }
  },
  mounted: function () {
    this.$store.dispatch('raspberry/getRsbShopList')
    this.$store.dispatch('raspberry/getAllRsbs')
    this.$store.dispatch('raspberry/getAllBoundsPlayList')
    this.$store.dispatch('raspberry/getAllPlayLists')
    this.$store.dispatch('raspberry/getAllGalleries')
    this.$store.dispatch('raspberry/getAllMusicLists')
    // setInterval(()=>{console.log(this.oldBinding, this.selectedShop_BIND, this.selectRaspberry_BIND, this.selectedGallery_BIND, this.selectedAlbom_BIND)}, 3000)
  },
  methods: {
    handleExceed() {
        this.$message({
          showClose: true,
          message: 'Количество файлов не должно быть больше 5!',
          type: 'warning',
          customClass: 'message'
        });
      },
    getOldBinding(){
      this.oldBinding = [+this.selectRaspberry_BIND, +this.selectedRaspberryPlayList_BIND !== +this.selectedRaspberryPlayList_BIND ? 0 : +this.selectedRaspberryPlayList_BIND]
      this.newBinding = this.oldBinding
    },
    getOldAlbomBinding(){
      this.oldAlbomsBinding = [+this.selectRaspberry_BIND, +this.selectedGallery_BIND !== +this.selectedGallery_BIND ? 0 : +this.selectedGallery_BIND, +this.selectedAlbom_BIND !== +this.selectedAlbom_BIND ? 0 : +this.selectedAlbom_BIND]
      this.newAlbomsBinding = this.oldAlbomsBinding
    },
    setNewBindig(){
      this.newBinding = [+this.selectRaspberry_BIND, +this.selectedRaspberryPlayList_BIND !== +this.selectedRaspberryPlayList_BIND ? 0 : +this.selectedRaspberryPlayList_BIND]
      console.log(this.newBinding)
      // this.newBinding = [+this.selectRaspberry_BIND, +this.selectedGallery_BIND !== +this.selectedGallery_BIND ? 0 : +this.selectedGallery_BIND, +this.selectedAlbom_BIND !== +this.selectedAlbom_BIND ? 0 : +this.selectedAlbom_BIND]
    },
    getOldAlbomsBinding(){
      this.oldAlbomsBinding = [+this.selectRaspberry_BIND, +this.selectedGallery_BIND !== +this.selectedGallery_BIND ? 0 : +this.selectedGallery_BIND, +this.selectedAlbom_BIND !== +this.selectedAlbom_BIND ? 0 : +this.selectedAlbom_BIND]
      this.newAlbomsBinding = this.oldAlbomsBinding
    },
    setNewAlbomsBindig(){
      this.newAlbomsBinding = [+this.selectRaspberry_BIND, +this.selectedGallery_BIND !== +this.selectedGallery_BIND ? 0 : +this.selectedGallery_BIND, +this.selectedAlbom_BIND !== +this.selectedAlbom_BIND ? 0 : +this.selectedAlbom_BIND]
      console.log(this.newAlbomsBinding)
    },
    // Методы для работы с музыкой и альбомамы
    submitMusicUpload() {
      this.$refs.music.submit()
    },
    uploadMusicsToServer(file){
      let albomId = this.selectedAlbom
      if(+albomId != +albomId) {
        this.swal.fire("Ошибка", "Для загрузки музыки нужно выбрать альбом!")
        return
      }
      this.uploadingMusic = true
      var FormData = require('form-data')
      var data = new FormData()
      data.append('sounds[main][]', file.file)

      var config = {
        method: 'post',
        url: 'https://tv.xleb.ru/api/v1/secure/upload_media',
        headers: { 
          'Accept': 'application/json', 
          'Content-Type': 'multipart/form-data'
        },
        data
      }
      console.log()
      this.axios(config)
      .then(response=>{
        console.log(response)
        var data = JSON.stringify({
          "file": response.data[0],
          "music_play_list": albomId,
          "sort": 1,
          "created_at": new Date(),
          "updated_at": new Date()
        });

        var config = {
          method: 'post',
          url: 'https://tv.xleb.ru/api/v1/secure/music',
          headers: { 
            'Content-Type': 'application/json', 
          },
          data
        };
        console.log(config)

        this.axios(config)
        .then(()=>{
          this.musicsList = []
          this.$refs.music.clearFiles()
          this.uploadingMusic = false
          this.$message({
            showClose: true,
            message: 'Музыка успешно добавлена!',
            type: 'success',
            customClass: 'message'
          });
        })
        .catch(()=>{
          this.uploadingMusic = false
          this.$message({
            showClose: true,
            message: 'Не удалось загрузить музыку!',
            type: 'error',
            customClass: 'message'
          });
        });
      })
      .catch(()=>{
        this.uploadingMusic = false
        this.$message({
          showClose: true,
          message: 'Не удалось загрузить музыку!',
          type: 'error',
          customClass: 'message'
        });
      });
    },
    createAlbom(){
      if(this.newMusicGallery.trim() == ""){
        this.swal.fire("Ошибка", "Нельзя создать альбом с пустым именем!")
        return
      }
      var data = JSON.stringify({
        "description": this.newMusicGallery,
      });

      var config = {
        method: 'post',
        url: 'https://tv.xleb.ru/api/v1/secure/musicPlayLists',
        headers: { 
          'Content-Type': 'application/json', },
        data
      };

      this.axios(config)
      .then(()=>{
        this.$store.dispatch('raspberry/getAllMusicLists')
        this.$message({
          showClose: true,
          message: 'Новый альбом успешно создан!',
          type: 'success',
          customClass: 'message'
        });
      })
      .catch(()=>{
        this.$message({
          showClose: true,
          message: 'Не удалось создать альбом!',
          type: 'error',
          customClass: 'message'
        });
      });
    },
    handleMusicUpload(){
      // this.$message({
      //   showClose: true,
      //   message: 'Файл был прикреплен!',
      //   customClass: 'message'
      // });
    },
    showBindedPlaylist(){
      // if(this.galleryCurrentShopFiltered_BIND[0]){
      //   this.selectedGallery_BIND = this.galleryCurrentShopFiltered_BIND[0].id
      // }else{
      //   this.selectedGallery_BIND = 'Не привязан плейлист...'
      // }
      // if(this.galleryCurrentShopFiltered_BIND[0]){
      //   this.selectedGallery_BIND = this.galleryCurrentShopFiltered_BIND[0].id
      // }else{
      //   this.selectedGallery_BIND = 'Не привязана галерея...'
      // }
      if(this.playlistBoundsCurrentShopFiltered_BIND[0]){
        console.log('=', this.playlistBoundsCurrentShopFiltered_BIND, this.playlistCurrentShopFiltered_BIND, this.playlistBounds)
        this.selectedRaspberryPlayList_BIND = this.playlistCurrentShopFiltered_BIND[0].id
        this.raspAlreadyHavePlaylist = this.playlistBoundsCurrentShopFiltered_BIND[0].id
      }else{
        this.selectedRaspberryPlayList_BIND = 'Не привязан плейлист...'
        this.raspAlreadyHavePlaylist = 0
      }
      this.getOldBinding()
    },
    showGalleryAndAlboms(){
      console.log(this.selectedRaspberryPlayListForChangeAlboms_BIND, this.allPlayLists)
      let musicId = this.allPlayLists.filter(el=>el.id === this.selectedRaspberryPlayListForChangeAlboms_BIND)[0].music_play_list
      if(musicId){
        this.selectedAlbom_BIND = musicId
      }else{
        this.selectedAlbom_BIND = 'Не привязан альбом...'
      }
      let galleryId = this.allPlayLists.filter(el=>el.id === this.selectedRaspberryPlayListForChangeAlboms_BIND)[0].gallery
      if(galleryId){
        this.selectedGallery_BIND = galleryId
      }else{
        this.selectedGallery_BIND = 'Не привязана галерея...'
      }
      this.getOldAlbomBinding()
    },
    // ----------------------------------------------


    // Методы для работы с картинками и галереями
    deleteImage(id){
      var config = {
        method: 'delete',
        url: 'https://tv.xleb.ru/api/v1/secure/images/'+id,
      };

      this.axios(config)
      .then(()=> {
        this.$message({
          showClose: true,
          message: 'Картинка удалена!',
          type: 'success',
          customClass: 'message'
        });
        this.showRaspberryData()
      })
      .catch(()=> {
        this.$message({
          showClose: true,
          message: 'Не удалось удалить картинку!',
          type: 'error',
          customClass: 'message'
        });
      });
    },
    deleteMusic(id){
      this.findAndPuse()
      var config = {
        method: 'delete',
        url: 'https://tv.xleb.ru/api/v1/secure/music/'+id,
      };

      this.axios(config)
      .then(()=> {
        this.$message({
          showClose: true,
          message: 'Саундтрек удален!',
          type: 'success',
          customClass: 'message'
        });
        this.showRaspberryData()
      })
      .catch(()=> {
        this.$message({
          showClose: true,
          message: 'Не удалось удалить саундтрек!',
          type: 'error',
          customClass: 'message'
        });
      });
    },
    handleImageUpload(file){
      this.imagesList.push(file)
      this.$message({
        showClose: true,
        message: 'Файл был прикреплен!',
        customClass: 'message'
      });
    },
    handleImageRemove(file, imagesList) {
      let index
      imagesList.forEach((element, i) => {
        if(element.uid === file.uid){
          index = i
        }
      });
      imagesList.splice(index, 1)
      this.$message({
        showClose: true,
        message: 'Файл был откреплен!',
        customClass: 'message'
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    uploadImagesToServer(file){
      console.log('file---->', file)
      let galleryId = this.selectedGallery
      if(+galleryId != +galleryId) {
        this.swal.fire("Ошибка", "Для загрузки изображения нужно выбрать галерею!")
        return
      }
      this.uploadingImage = true
      var FormData = require('form-data')
      var data = new FormData()
      if(file.file.type == "image/jpeg"){
        data.append('images[main][]', file.file)
      }else if(file.file.type == "video/mp4"){
        data.append('videos[main][]', file.file)
      }

      var config = {
        method: 'post',
        url: 'https://tv.xleb.ru/api/v1/secure/upload_media',
        headers: { 
          'Accept': 'application/json', 
          'Content-Type': 'multipart/form-data'
        },
        data
      }

      this.axios(config)
      .then(response=>{
        var data = JSON.stringify({
          "file": response.data[0],
          "gallery": galleryId,
          "sort": 1,
          "created_at": new Date(),
          "updated_at": new Date()
        });

        var config = {
          method: 'post',
          url: 'https://tv.xleb.ru/api/v1/secure/images',
          headers: { 
            'Content-Type': 'application/json', 
          },
          data : data
        };
        // setTimeout(()=>{
          this.axios(config)
          .then(()=>{
            this.imagesList = []
            this.$refs.upload.clearFiles()
            this.uploadingImage = false
            this.$message({
              showClose: true,
              message: 'Изображение успешно добавлено!',
              type: 'success',
              customClass: 'message'
            });
          })
          .catch(()=>{
            this.uploadingImage = false
            this.$message({
              showClose: true,
              message: 'Не удалось загрузить изображение!',
              type: 'error',
              customClass: 'message'
            });
          });
        // }, 2000)
        
      })
      .catch(()=>{
        this.uploadingImage = false
        this.$message({
          showClose: true,
          message: 'Не удалось загрузить изображение!',
          type: 'error',
          customClass: 'message'
        });
      });

    },
    submitImageUpload() {
      this.$refs.upload.submit()
    },
    findAndPuse(){
      let played = document.querySelector('.played')
      if(played){
        played.classList.remove('played')
        played.querySelector('audio').pause()
        played.querySelector('.music-player').classList.add('music-player--stop')
        clearInterval(this.currentAudio)
      }
    },
    playOrPause(id){
      let container = document.querySelector('#'+id)
      let audio = document.querySelector('#'+id+' audio')
      if(container.classList.contains('music-player--stop')){
        this.findAndPuse()
        container.parentElement.classList.add('played')
        container.classList.remove('music-player--stop')
        this.currentAudio = setInterval(()=>{
          this.currentTime = (audio.currentTime/audio.duration*100).toFixed(2)
        }, 10)
        audio.play()
        let self = this
        audio.addEventListener("ended", function(){
          self.playOrPause(id)
        })
      }else{
        this.currentTime = 0
        clearInterval(this.currentAudio)
        audio.currentTime = 0
        container.parentElement.classList.remove('played')
        container.classList.add('music-player--stop')
        audio.pause()
      }
    },
    createGallery(){
      if(this.newImageGallery.trim() == ""){
        this.swal.fire("Ошибка", "Нельзя создать галерею с пустым именем!")
        return
      }
      var data = JSON.stringify({
        "description": this.newImageGallery,
      });

      var config = {
        method: 'post',
        url: 'https://tv.xleb.ru/api/v1/secure/galleries',
        headers: { 
          'Content-Type': 'application/json', },
        data
      };

      this.axios(config)
      .then(()=>{
        this.$store.dispatch('raspberry/getAllGalleries')
        this.$message({
          showClose: true,
          message: 'Новая галерея успешно создана!',
          type: 'success',
          customClass: 'message'
        });
      })
      .catch(()=>{
        this.$message({
          showClose: true,
          message: 'Не удалось создать галерею!',
          type: 'error',
          customClass: 'message'
        });
      });
    },
    createPlayList(){
      if(this.newPlayList.trim() == ""){
        this.swal.fire("Ошибка", "Нельзя создать плейлист с пустым именем!")
        return
      }
      var data = JSON.stringify({
        "description": this.newPlayList,
        "music_play_list": 0,
        "gallery": 0,
      });

      var config = {
        method: 'post',
        url: 'https://tv.xleb.ru/api/v1/secure/playLists',
        headers: { 
          'Content-Type': 'application/json', },
        data
      };

      this.axios(config)
      .then((response)=>{
        this.newPlayList = ""
        this.$store.dispatch('raspberry/getAllPlayLists')
        this.$message({
          showClose: true,
          message: 'Новый плейлист успешно создан!',
          type: 'success',
          customClass: 'message'
        });

        if(this.raspAlreadyHavePlaylist == 0){
          let data = {
            "raspbery": this.selectRaspberry_BIND,
            "play_list": response.data.data.id
          }
          
          let c = {
            method: 'post',
            url: 'https://tv.xleb.ru/api/v1/secure/raspberryPlayLists',
            headers: { 
              'Content-Type': 'application/json', },
            data
          };
          this.axios(c)
          .then(()=>{
            this.$store.dispatch('raspberry/getAllBoundsPlayList')
            this.$message({
              showClose: true,
              message: 'Новый плейлист успешно привязан!',
              type: 'success',
              customClass: 'message'
            });
          })
          .catch(()=>{
            this.$message({
              showClose: true,
              message: 'Не удалось привязать плейлист!',
              type: 'error',
              customClass: 'message'
            });
          });
        }else{
          let data = {
            "id": this.raspAlreadyHavePlaylist,
            "raspbery": this.selectRaspberry_BIND,
            "play_list": response.data.data.id
          }
          
          let c = {
            method: 'patch',
            url: 'https://tv.xleb.ru/api/v1/secure/raspberryPlayLists/'+this.raspAlreadyHavePlaylist,
            headers: { 
              'Content-Type': 'application/json', },
            data
          };
          console.log(data, '=-034=-2923-402=04')
          this.axios(c)
          .then(()=>{
            this.$store.dispatch('raspberry/getAllBoundsPlayList')
            this.$message({
              showClose: true,
              message: 'Новый плейлист успешно привязан!',
              type: 'success',
              customClass: 'message'
            });
          })
          .catch(()=>{
            this.$message({
              showClose: true,
              message: 'Не удалось привязать плейлист!',
              type: 'error',
              customClass: 'message'
            });
          });
        }
      })
      .catch(()=>{
        this.$message({
          showClose: true,
          message: 'Не удалось создать плейлист!',
          type: 'error',
          customClass: 'message'
        });
      });
    },
    changePlayList(){
      var data = JSON.stringify({
        "id": this.selectedRaspberryPlayListForChangeAlboms_BIND,
        "music_play_list": this.selectedAlbom_BIND,
        "gallery": this.selectedGallery_BIND,
      });
      console.log(data, '+++++++++++++++++++')
      var config = {
        method: 'PUT',
        url: 'https://tv.xleb.ru/api/v1/secure/playLists/'+this.selectedRaspberryPlayListForChangeAlboms_BIND,
        headers: { 
          'Content-Type': 'application/json', },
        data
      };

      this.axios(config)
      .then((response)=>{
        this.newPlayList = ""
        this.$store.dispatch('raspberry/getAllPlayLists')
        this.$message({
          showClose: true,
          message: 'Новый плейлист успешно создан!',
          type: 'success',
          customClass: 'message'
        });
        console.log("reSP", response)
        // if(this.raspAlreadyHavePlaylist == 0){
        //   let data = {
        //     "raspbery": this.selectRaspberry_BIND,
        //     "play_list": response.data.data.id
        //   }
          
        //   let c = {
        //     method: 'post',
        //     url: 'https://tv.xleb.ru/api/v1/secure/raspberryPlayLists',
        //     headers: { 
        //       'Content-Type': 'application/json', },
        //     data
        //   };
        //   this.axios(c)
        //   .then(()=>{
        //     this.$store.dispatch('raspberry/getAllBoundsPlayList')
        //     this.$message({
        //       showClose: true,
        //       message: 'Новый плейлист успешно привязан!',
        //       type: 'success',
        //       customClass: 'message'
        //     });
        //   })
        //   .catch(()=>{
        //     this.$message({
        //       showClose: true,
        //       message: 'Не удалось привязать плейлист!',
        //       type: 'error',
        //       customClass: 'message'
        //     });
        //   });
        // }else{
        //   let data = {
        //     "id": this.raspAlreadyHavePlaylist,
        //     "raspbery": this.selectRaspberry_BIND,
        //     "play_list": response.data.data.id
        //   }
          
        //   let c = {
        //     method: 'patch',
        //     url: 'https://tv.xleb.ru/api/v1/secure/raspberryPlayLists/'+this.raspAlreadyHavePlaylist,
        //     headers: { 
        //       'Content-Type': 'application/json', },
        //     data
        //   };
        //   console.log(data, '=-034=-2923-402=04')
        //   this.axios(c)
        //   .then(()=>{
        //     this.$store.dispatch('raspberry/getAllBoundsPlayList')
        //     this.$message({
        //       showClose: true,
        //       message: 'Новый плейлист успешно привязан!',
        //       type: 'success',
        //       customClass: 'message'
        //     });
        //   })
        //   .catch(()=>{
        //     this.$message({
        //       showClose: true,
        //       message: 'Не удалось привязать плейлист!',
        //       type: 'error',
        //       customClass: 'message'
        //     });
        //   });
        // }
      })
      .catch(()=>{
        this.$message({
          showClose: true,
          message: 'Не удалось создать плейлист!',
          type: 'error',
          customClass: 'message'
        });
      });
    },
    // ----------------------------------------------


    async showRaspberryData () {
      // let url = 'https://tv.xleb.ru/api/v1/tv/get-data/'+this.selectRaspberry
      // let response = await this.axios.get(url)
      // console.log(response.data)

      let config = {
        method: 'get',
        url: 'https://tv.xleb.ru/api/v1/secure/raspberryPlayLists',
        headers: { 
          'Content-Type': 'application/json', 
        },
      };
      let selectedRasp = this.selectRaspberry

      this.axios(config).then(response=>{
        let filteredBoundRaspToList = response.data.data.filter(function(el){return el.raspbery == selectedRasp})

        let config = {
          method: 'get',
          url: 'https://tv.xleb.ru/api/v1/secure/playLists/'+filteredBoundRaspToList[0].play_list,
          headers: { 
            'Content-Type': 'application/json', 
          },
        };
        this.axios(config).then(response=>{
          let music_play_list = response.data.data.music_play_list
          let gallery = response.data.data.gallery

          this.axios({url:'https://tv.xleb.ru/api/v1/secure/images'}).then(response=>{
            let filteredImagesByList = response.data.data.filter(function(el){return el.gallery == gallery})

            this.axios({url:'https://tv.xleb.ru/api/v1/secure/music'}).then(response=>{
              let filteredMusicByList = response.data.data.filter(function(el){return el.music_play_list == music_play_list})
              filteredImagesByList.forEach(el=>{
                el.file = el.file.replace('app/public/', '')
              })
              let data = {
                music: filteredMusicByList,
                images: filteredImagesByList
              }
              this.raspData = data
            })
          })
        })
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    imageUrl(name){
      return 'https://tv.xleb.ru/'+name
    },
    imageUrlList(images){
      let arr=[]
      for(let id in images){
        arr.push('https://tv.xleb.ru/'+images[id].file)
      }
      console.log("arr",arr)
      return arr
    },
    show(){
      console.log(this.selectedShop)
    },
    sendNewBinding(){
      let data = {
        "id": this.raspAlreadyHavePlaylist,
        "raspbery": this.selectRaspberry_BIND,
        "play_list": this.selectedRaspberryPlayList_BIND
      }
      
      let c = {
        method: 'patch',
        url: 'https://tv.xleb.ru/api/v1/secure/raspberryPlayLists/'+this.raspAlreadyHavePlaylist,
        headers: { 
          'Content-Type': 'application/json', },
        data
      };
      console.log(data, '=-034=-2923-402=04')
      this.axios(c)
      .then(()=>{
        this.$store.dispatch('raspberry/getAllBoundsPlayList')
        this.$message({
          showClose: true,
          message: 'Новый плейлист успешно привязан!',
          type: 'success',
          customClass: 'message'
        });
        this.oldBinding = this.newBinding
      })
      .catch(()=>{
        this.$message({
          showClose: true,
          message: 'Не удалось привязать плейлист!',
          type: 'error',
          customClass: 'message'
        });
      });

      // this.uploadNewBindings = true
      // var data = JSON.stringify({
      //   "id": this.playlistCurrentShopFiltered_BIND[0].id,
      //   "music_play_list": this.selectedAlbom_BIND,
      //   "gallery": this.selectedGallery_BIND,
      // });
      // console.log('----> DATA',data)
      // var config = {
      //   method: 'put',
      //   url: 'https://tv.xleb.ru/api/v1/secure/playLists/'+this.playlistCurrentShopFiltered_BIND[0].id,
      //   headers: { 
      //     'Content-Type': 'application/json', 
      //   },
      //   data
      // };

      // this.axios(config)
      // .then(()=>{
      //   this.uploadNewBindings = false
      //   this.oldBinding = this.newBinding
      //   this.$message({
      //     showClose: true,
      //     message: 'Плейлист успешно !',
      //     type: 'success',
      //     customClass: 'message'
      //   });
      // })
      // .catch(()=>{
      //   this.uploadNewBindings = false
      //   this.$message({
      //     showClose: true,
      //     message: 'Не получилось изменить плейлисты!',
      //     type: 'error',
      //     customClass: 'message'
      //   });
      // });
    }
  },
  computed: {
    ...mapState({
      listOfShops: state => state.raspberry.rsbShopList,
      rsbInCurrentShop: state => state.raspberry.allRaspberries,
      playlistBounds: state => state.raspberry.allRsbBounds,
      allPlayLists: state => state.raspberry.allPlayLists,
      allGalleries: state => state.raspberry.allGalleries,
      allMusicLists: state => state.raspberry.allMusicLists,
    }),
    compareOldAndNewBindings(){
      return JSON.stringify(this.newBinding) === JSON.stringify(this.oldBinding)
    },
    compareOldAndNewAlbomsBindings(){
      return JSON.stringify(this.newAlbomsBindings) === JSON.stringify(this.oldAlbomsBinding)
    },
    //-----------------------------------------------------------------------------
    rsbInCurrentShopFiltered: function(){
      return this.rsbInCurrentShop.filter(el=>el['shop']==this.selectedShop)
    },
    rsbInCurrentShopFiltered_BIND: function(){
      return this.rsbInCurrentShop.filter(el=>el['shop']==this.selectedShop_BIND)
    },
    //-----------------------------------------------------------------------------
    playlistBoundsCurrentShopFiltered: function(){
      return this.playlistBounds.filter(el=>el['raspbery']==this.selectRaspberry)
    },
    playlistBoundsCurrentShopFiltered_BIND: function(){
      return this.playlistBounds.filter(el=>el['raspbery']==this.selectRaspberry_BIND)
    },
    //-----------------------------------------------------------------------------
    playlistCurrentShopFiltered: function(){
      if(this.playlistBoundsCurrentShopFiltered.length){
        return this.allPlayLists.filter(el=>el['id'] == this.playlistBoundsCurrentShopFiltered[0].play_list)
      }
      return 0
    },
    playlistCurrentShopFiltered_BIND: function(){
      if(this.playlistBoundsCurrentShopFiltered_BIND.length){
        return this.allPlayLists.filter(el=>el['id'] == this.playlistBoundsCurrentShopFiltered_BIND[0].play_list)
      }
      return 0
    },
    //-----------------------------------------------------------------------------
    galleryCurrentShopFiltered: function(){
      if(this.playlistCurrentShopFiltered.length){
        return this.allGalleries.filter(el=>el['id'] == this.playlistCurrentShopFiltered[0].gallery)
      }
      return 0
    },
    galleryCurrentShopFiltered_BIND: function(){
      if(this.playlistCurrentShopFiltered_BIND.length){
        return this.allGalleries.filter(el=>el['id'] == this.selectedRaspberryPlayListForChangeAlboms_BIND[0].gallery)
      }
      return 0
    },
    //-----------------------------------------------------------------------------
    getCurGalleryName: function(){
      if(this.galleryCurrentShopFiltered[0]){
        return this.galleryCurrentShopFiltered[0].description
      }
      return 0
    },
    // getCurGalleryName_BIND: function(){
    //   if(this.galleryCurrentShopFiltered_BIND[0]){
    //     this.selectedGallery_BIND = this.galleryCurrentShopFiltered_BIND[0].id
    //   }
    //   return 0
    // },
    //-----------------------------------------------------------------------------
    musicListCurrentShopFiltered: function(){
      console.log(this.allMusicLists)
      if(this.playlistCurrentShopFiltered.length){
        return this.allMusicLists.filter(el=>el['id'] == this.playlistCurrentShopFiltered[0].music_play_list)
      }
      return 0
    },
    musicListCurrentShopFiltered_BIND: function(){
      console.log(this.allMusicLists)
      if(this.playlistCurrentShopFiltered_BIND.length){
        return this.allMusicLists.filter(el=>el['id'] == this.selectedRaspberryPlayListForChangeAlboms_BIND[0].music_play_list)
      }
      return 0
    },
    //-----------------------------------------------------------------------------
    getCurMusicListName: function(){
      if(this.musicListCurrentShopFiltered.length){
        return this.musicListCurrentShopFiltered[0].description
      }
      return 0
    },
    getCurMusicListName_BIND: function(){
      if(this.musicListCurrentShopFiltered_BIND.length){
        return this.musicListCurrentShopFiltered_BIND[0].description
      }
      return 0
    },
    //-----------------------------------------------------------------------------
    isShopSelected: function(){
      return this.selectedShop == "Выберите торговую точку..."
    },
    isShopSelected_BIND: function(){
      return this.selectedShop_BIND == "Выберите торговую точку..."
    },
    //-----------------------------------------------------------------------------
    isEmptyImage: function (){
      return Object.keys(this.raspData).length !== 0 && this.raspData.images.length !==0
    },
    isEmptyMusic: function (){
      return Object.keys(this.raspData).length !== 0 && this.raspData.music.length !==0
    },
    //-----------------------------------------------------------------------------
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    //-----------------------------------------------------------------------------
    musicCurTimePercent() {
      return this.currentTime
    }
  },
}
</script>

<style lang="scss">
  .raspberry{
    padding: 1px 0;
    & .el-card, & .el-collapse-item__wrap{
      overflow: visible;
    }

    &__music-element{
      width: 100%;
      max-width: 500px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
      padding-left: 20px;
      padding-right: 20px;
      border: 1px solid lavenderblush;  
      &:hover > .delete-music-btn{
        opacity: 1;
      }
      // &.played .music-player{
      //   opacity: 1;
      // }
    }
  }
  .music__wrapper{
    display: grid;
    gap: 0 10px;
    grid-template-columns: 1fr;
  }
  @media (min-width: 756px) {
    .music__wrapper{
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (min-width: 1440px) {
    .music__wrapper{
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (max-width: 756px) {
    .el-divider__text{
      font-size: 12px;
    }
  }
  .el-select{
    max-width: 100%;
    width: 400px;
  }
  .el-select-dropdown__item.selected{
    color: #ff6900;
  }
  .el-card__body{
    padding: 0 20px;
  }
  .el-collapse{
    border: none;
  }
  .el-collapse-item__header{
    border: none;
    line-height: unset !important;
    & > div{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .list-group {
    min-height: 20px;
  }
  .list-group-item {
    cursor: move;
  }
  .message{
    z-index: 99999 !important;
  }

  .image-wrapper{
    overflow: hidden;
    & .delete-image-btn{
      cursor: pointer;
      opacity: 0;
      font-size: 24px !important;
      position: absolute;
      right: 8px;
      border-radius: 0 .25rem .25rem 0!important;
      background-color: rgba(#fe7a15, .8);
      color: white;
      height: calc(100% - 23px);
      align-items: center;
      transition: all .3s ease;
    }
    &:hover .delete-image-btn{
      opacity: 1;
    }
  }

  .delete-music-btn{
    opacity: 0;
    right: 0;
    background-color: rgba(#fe7a15, .8);
    color: white;
    border-radius: 0 .25rem .25rem 0!important;
    height: 100%;
  }
  
  .el-select__popper{
    max-width: 90%;
  }

  .music-player{
    background-color: rgba(red, .8);
    border-radius: .25rem 0 0 .25rem !important;
    color: white;
    display: flex;
    flex-direction: row;
    height: 100%;
    left: 0;
    opacity: 0;
    font-size: 22px;
    & .el-icon-video-pause{
      display: inline-block;
    }
    & .el-icon-video-play{
      display: none;
    }
    &--stop{
      background-color: rgba(#29bb89, .8);
      & .el-icon-video-pause{
        display: none;
      }
      & .el-icon-video-play{
        display: inline-block;
      }
    }
  }
  video{
    height: 100%;
    border-radius: .25rem!important;
    background-color: black;
  }

  .duration{
    position: absolute;
    display: none;
    left: 0;
    width: 0%;
    bottom: 0;
    height: 100%;
    background-color: rgb(170, 255, 244, .5);
  }
  .played .duration{
    display: block;
  }
  .raspberry__music-element:hover .delete-music-btn, .raspberry__music-element:hover .music-player{
    opacity: 1;
  }

</style>