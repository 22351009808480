<template>
  <footer class="main-footer">
    <div class="pull-right hidden-xs">Версия <b>1.0</b> alpha</div>
    © 2021 ООО «Хлебник Франчайзинг»
  </footer>
</template>
<script>
  export default {
    name: 'DashboardFooter'
  }
</script>

