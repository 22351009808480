<template>
  <div>
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="content-wrapper dashboard-wrapper" style="min-height: 100vh;">
      <section class="content">
        <router-view></router-view>
      </section>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import Sidebar from './components/partials/Sidebar.vue'
  import Header from './components/partials/Header.vue'
  import Footer from './components/partials/Footer.vue'

  export default {
    name: 'Dashboards',
    components: {
      'app-sidebar': Sidebar,
      'app-header': Header,
      'app-footer': Footer,
    }
  }
</script>

<style lang="scss">
  @import "sass/main.scss"
</style>

<style lang="scss" scoped>
  #app > div{
    overflow: hidden;
  }
</style>
