import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../components/Login.vue'
import Raspberry from '../components/Raspberry.vue'
import Messages from '../components/Messages.vue'

import store from "../store/index.js"

const routes = [
  {
    meta: {title: 'Управление мониторами'},
    path: '/',
    name: 'Raspberry',
    component: Raspberry
  },
  {
    meta: {title: 'Окно авторизации'},
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    meta: {title: 'Отправка сообщений на кассу'},
    path: '/messages',
    name: 'Messages',
    component: Messages
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  if(!document.body.className.includes("skin-light")) document.body.className += ' skin-light'
  if(!document.body.className.includes("sidebar-mini")) document.body.className += ' sidebar-mini'
  

  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.getters.isLoggedIn;
  document.title = to.meta.title
  if (authRequired && !loggedIn) {
    return next('/login');
  }else if(!authRequired && loggedIn){
    return next('/');
  }else{
    next()
  }
})

export default router
