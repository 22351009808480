import axios from 'axios'

export default {
  auth_request(state){
    state.status = 'loading'
  },
  auth_success(state, token, user){
    state.status = 'success'
    state.token = token
    state.user = user
  },
  auth_error(state){
    state.status = 'error'
  },
  logout(state){
    state.status = ''
    state.token = ''

    delete axios.defaults.headers.common['Authorization']
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    localStorage.removeItem('shopList')
  },
  
  SET_SHOP_LIST(state, list){
    state.shopList = list
    localStorage.setItem('shopList', JSON.stringify(list))
  },
  SET_LAST_5_WEEKS(state, weeks){
    state.last5weeks = weeks
    state.last5weeksLoading = false
  },
  SET_ALL_CASHIERS(state, list){
    state.allCashiers = list
  }
}