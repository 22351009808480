export default {
  SET_RSBPI_SHOP_LIST(state, list){
    state.rsbShopList = list
  },
  SET_RSBPI(state, list){
    state.allRaspberries = list
  },
  SET_RSBPI_BOUNDS(state, list){
    state.allRsbBounds = list
  },
  SET_PLAY_LISTS(state, list){
    state.allPlayLists = list
  },
  SET_ALL_GALLERIES(state, galleries){
    state.allGalleries = galleries
  },
  SET_ALL_MUSIC_LISTS(state, list){
    state.allMusicLists = list
  },
}